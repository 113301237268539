<template>
  <article
    v-if="!loading"
    class="box"
  >
    <h1 class="page-title">
      {{ localDocumentTitle }}
    </h1>

    <div v-if="sessions.length">
      <VRow>
        <VCol
          v-for="session in sessions"
          :key="session.order_id"
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
        >
          <SessionCard
            :to="getTo(session)"
            :session="session"
            refreshable
            :is-show-documents="session.is_active && session.is_started && !!session.documents_count"
          />
        </VCol>
      </VRow>
    </div>

    <UPlaceholder v-else />
  </article>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SessionCard from '@components/SessionCard.vue'
import documentTitle from '@/mixins/documentTitle'
import UPlaceholder from '@components/UPlaceholder.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

export default {
  name: 'TheSessions',

  components: {
    UPlaceholder,
    SessionCard
  },

  mixins: [
    documentTitle
  ],

  data () {
    return {
      documentTitle: 'sessions.other',
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      sessions: getters.SESSIONS
    })
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      getSessions: actions.GET_SESSIONS
    }),

    getTo (session) {
      if (session.is_started && (session.is_active || session.order_status.code === 'training_complete')) {
        return { name: 'session', params: { sessionID: session.id } }
      }

      return ''
    },

    async register () {
      try {
        await this.getSessions()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
